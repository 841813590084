import React, { useCallback, useEffect, useState } from 'react';

import ZJAddArticlePage from '../ZJAddArticlePage/ZJAddArticlePage';
import ZJAdminLoginPage from '../ZJAdminLoginPage/ZJAdminLoginPage';
import ZJAdminDataManager from '../ZJAdminDataManager/ZJAdminDataManager';
import ZJAdminLogoutButton from '../ZJAdminLoginPage/ZJAdminLogoutButton';
import axios from 'axios';
import ZJUpdateArticle from '../ZJUpdateArticle/ZJUpdateArticle';
import ZJDeleteArticleButton from '../ZJDeleteArticleButton/ZJDeleteArticleButton';

const ZJArticleAdminCenter = () => {
    const [showAddArticlePage, setShowAddArticlePage] = useState(false)
    const [showLoginPage, setShowLoginPage] = useState(false)
    const [showReeditPage, setShowReeditPage] = useState(false)
    const [loginState, setLoginState] = useState(ZJAdminDataManager.getAdminToken() != null ? true : false)
    const [articleArray, setArticleArray] = useState([])
    const [currentReEditArticle, setCurrentReEditArticle] = useState("")


    const loadArticleList = () => {
        let nodesArray = []
        for (let i = 0; i < articleArray.length; i++) {
            let each = articleArray[i];
            nodesArray.push(
                <div key={i}>
                    <div>{each["id"]}</div>
                    <div>{each["content"]}</div>
                    <div>{each["title"]}</div>
                    <div>
                        <br />
                        <button
                            onClick={() => {
                                updateArticle(JSON.stringify(each))
                            }}>修改</button>
                        <br />

                        <ZJDeleteArticleButton
                            articleID={each["id"]}
                            deleteSuccessCallBack={() => {
                                refreshArticleList()
                            }}
                            failToDeleteCallBack={() => {

                            }} />
                    </div>
                    <br />
                    <br />
                </div>
            )
        }
        return <div> {nodesArray}</div>
    }

    const updateArticle = (article: string) => {
        setShowReeditPage(true)
        setCurrentReEditArticle(article);
    }

    const getReEditArticleID = () => {
        if (currentReEditArticle != null && currentReEditArticle.length > 0) {
            let article = JSON.parse(currentReEditArticle)
            return article["id"]
        }
        return ""
    }
    const getReEditArticleTitle = () => {
        if (currentReEditArticle != null && currentReEditArticle.length > 0) {
            let article = JSON.parse(currentReEditArticle)
            return article["title"]
        }
        return ""
    }

    const getReEditArticleContent = () => {
        if (currentReEditArticle != null && currentReEditArticle.length > 0) {
            let article = JSON.parse(currentReEditArticle)
            return article["content"]
        }
    }

    const refreshArticleList = useCallback(() => {
        if (loginState === false) {
            return;
        }
        let data = {token: ZJAdminDataManager.getAdminToken()}
        axios.post("/api/article/article_list", data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data && response.data.status && response.data.status === "0") {
                        return response.data.data
                    }
                }
                return null
            }, error => {
                return null
            }).then(jsonDataString => {
                if (jsonDataString != null) {
                    let array = JSON.parse(jsonDataString)
                    if (array != null && array.length > 0) {
                        setArticleArray(array)
                    }
                }
            })
    }, [loginState])

    useEffect(() => {
        refreshArticleList()
    }, [refreshArticleList]);

    const loginStateDiv = () => {
        if (loginState) {
            return (<div>
                <div>
                    <div>您已经登录，欢迎回来。</div>
                    <ZJAdminLogoutButton
                        callBackFun={() => {
                            // 退出登录成功
                            setLoginState(false)
                            window.location.reload()
                        }} />
                </div>
            </div>)
        } else {
            return <div>
                <button
                    onClick={() => {
                        setShowLoginPage(true)
                    }}>未登录，请点击登录
                </button></div>
        }
    }

    return (
        <div className="main_content_container">
            <div>文章管理中心</div>
            <div>
                {loginStateDiv()}
            </div>
            <div>
                <button onClick={() =>
                    setShowAddArticlePage(true)
                }>添加文章</button><br />
            </div>

            <ZJAddArticlePage
                show={showAddArticlePage}
                finishAddFun={() => {
                    setShowAddArticlePage(false)
                    refreshArticleList()
                }} />

            <ZJAdminLoginPage
                show={showLoginPage}
                finishAddFun={() => {
                    // 登录成功回调
                    setShowLoginPage(false)
                    setLoginState(true)
                    refreshArticleList()
                }} />

            <ZJUpdateArticle
                show={showReeditPage}
                initArticleID={getReEditArticleID()}
                initTitle={getReEditArticleTitle()}
                initContent={getReEditArticleContent()} finishUpdateFun={() => {
                    // 更新成功，返回来，直接关闭页面
                    setShowReeditPage(false)
                    refreshArticleList()
                }} />
            <div>
                {loadArticleList()}
            </div>
        </div>
    )
}

export default ZJArticleAdminCenter