import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LCFileDownloader from "../Tools/LCFileDownloader";
import LCCPlusPlusEditor from "../Components/LCCPlusPlusEditor/LCCPlusPlusEditor";
import { useLocation } from 'react-router-dom';
import LCPythonEditor from "../Components/LCPythonEditor/LCPythonEditor";
import './LCCodePlayground.css'
import LCCodePerformTool from "../Tools/LCCodePerformTool";

/**
 * 
 关于参数
 下面的json base64之后放到path里面也就是 ?info= 后面即可
 具体
 language 可选参数 c++ 或者 python
 code_content和file 写一个参数即可，如果是  code_content 那么是具体代码的base64编码，file那么是文件的远程储存地址
 hightlight要高亮显示的代码行数
{
"language":"c++",
"code_content":"",
"file":"",
"highlight":[1, 2,3]
}

langugage说明语言类型，
content:要解析的代码
file:"":如果是文件，那么获取到文件然后下载；
highlight_lines 要重点突出的行数

上述JSON整体base64一下，放到path里面然后这个文件获取,content需要整体base64,之后再次base64

example1
http://localhost:3000/code_playground?info=ewoibGFuZ3VhZ2UiOiJjKysiLAoiY29kZV9jb250ZW50IjoiSTJsdGNHOXlkQ0E4YVc5emRISmxZVzB1YUQ0S0NtbHVkQ0J0WVdsdUtDbDdDaUFnSUhKbGRIVnliaUF5TWpzS2ZRPT0iLAoiaGlnaGxpZ2h0IjpbMSwgMiw1XQp9

example2
http://localhost:3000/code_playground?info=ewoibGFuZ3VhZ2UiOiJweXRob24iLAoiY29kZV9jb250ZW50IjoiWkdWbUlHRmtaQ2hoTENCaUtUb0tJQ0FnSUhKbGRIVnliaUFvWVNBcklHSXBPd289IiwKImhpZ2hsaWdodCI6WzFdCn0=

example3 
参数
{
"language":"c++",
"code_content":"I2ltcG9ydCA8aW9zdHJlYW0uaD4KCmludCBtYWluKCl7CiAgIHJldHVybiAyMjsKfQ==",
"highlight":[1, 2,5]
}

example4 参数
http://localhost:3000/code_playground?info=ewoibGFuZ3VhZ2UiOiJjKysiLAoiZmlsZSI6Imh0dHBzOi8vaG9uZ3hpbnlvdXppLmNvbS9jcGx1c3BsdXNjb2Rlcy8wMDFfMDAxXzAwMS50eHQiLAoiaGlnaGxpZ2h0IjpbMl0KfQ==
 */
const LCCodePlayground = function () {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const info = query.get('info');

    let currentCode = useRef("")
    let language: string = ""
    let pathCodeContent: string = ""
    let file: string = ""
    // let highlightLineNumbers = useMemo(() => {
    //     return []
    // }, []);
    if (info != null) {
        const infoString = atob(info);
        const infoObj = JSON.parse(infoString)
        let tempLanuage = infoObj["language"]
        if (tempLanuage) {
            language = tempLanuage
        }
        let tempPathCodeContent = infoObj["code_content"]
        if (tempPathCodeContent) {
            pathCodeContent = atob(tempPathCodeContent)
        }
        let tempFile = infoObj["file"]
        if (tempFile) {
            file = tempFile
        }
        let tempHighlightLines = infoObj["highlight"]
        if (tempHighlightLines) {
            // highlightLineNumbers = tempHighlightLines
        }
    }
    const initDiv =
        (<div>
            内容加载中,请稍等
        </div>);

    const [contentDiv, setContentDiv] = useState(initDiv);
    const [performanceResult, setPerformanceResult] = useState<string>("");
    const showPerformanceResult = function (result: string) {
        setPerformanceResult(result)
    }

    // useCallBack是为了在useEffect中使用
    const cplusplusEditorComponent = useCallback((codeValue: string) => {
        let desDiv = <div>init editor</div>
        if (language === "c++") {
            desDiv = (<LCCPlusPlusEditor codeValue={codeValue} updateCallBack={
                (value: string) => {
                    currentCode.current = value;
                }
            } />)
        } else if (language === "python") {
            desDiv = (<LCPythonEditor codeValue={codeValue} updateCallBack={
                (value: string) => {
                    currentCode.current = value;
                }
            } />)
        }
        return desDiv
    }, [language]);

    const failContentsDiv = useMemo(() => {
        return (
            <div>没有获取到文件内容</div>
        );
    }, []);
    const setUrlPathCodeContent = useMemo(() => {
        return (() => {
            if (pathCodeContent.length > 0) {
                setContentDiv(cplusplusEditorComponent(pathCodeContent))
            }
        });
    }, [pathCodeContent, cplusplusEditorComponent]);

    const downloadFile = useMemo(() => {
        return () => {
            LCFileDownloader.instance.downloadFile(file, (mapObj: Object) => {
                let success: boolean = false
                let codeContent = ""
                if (mapObj != null) {
                    let codeStatus = (mapObj as { statusKey: string }).statusKey;
                    if (codeStatus === "0") {
                        codeContent = (mapObj as { contentKey: string }).contentKey;
                        if (codeContent != null && codeContent.length > 0) {
                            success = true
                        }
                    }
                }
                const desContents = cplusplusEditorComponent(codeContent);
                if (success) {
                    setContentDiv(desContents)
                } else {
                    setContentDiv(failContentsDiv)
                }
            })
        }
    }, [failContentsDiv, cplusplusEditorComponent, file]);
    useEffect(() => {
        if (file.length > 0) {
            downloadFile()
        } else if (pathCodeContent.length > 0) {
            setUrlPathCodeContent()
        }
    }, [file, pathCodeContent, downloadFile, setUrlPathCodeContent]);

    const performCode = function () {
        showPerformanceResult("正在执行，请稍等")
        // 获取CodeMirror的值
        //   var value = editor.getValue();
        LCCodePerformTool.performCode(language, currentCode.current, (statusCode: string, content: string) => {
            let theResult = ""
            if (content.length === 0) {
                theResult = "代码执行成功"
            } else {
                theResult = atob(content)
            }
            
            showPerformanceResult(theResult)
        })
    }

    // TODO 需要解决重复渲染的问题，核心问题，重复渲染导致重复请求，重复请求导致重复渲染.
    return (
        <div>
            <div>
                {contentDiv}
            </div>
            <div>
                <button onClick={() => {
                    performCode()
                    // showPerformanceResult("ok")
                }}>点击这里</button><br />
                <div>{performanceResult}</div>
            </div>
        </div>
    )

}

export default LCCodePlayground