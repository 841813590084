import React, { ReactElement } from 'react';
import './device.css'
import ZJDeviceChecker from '../Tools/ZJDeviceChecker';

type DifferentDeviceFrameType = {
  // NOTE PETER 如果有使用到 children属性，那么使用  children: ReactElement,否则使用  children?: never[], 这个属于重载 overload
  children?: never[],
  computorChildrenDivs: ReactElement;
  phoneChildrenDivs: ReactElement;
};

class DifferentDeviceFrame extends React.Component<DifferentDeviceFrameType>   {
  public loadComputorPage(): React.ReactNode {
    return (
      <div>
        {this.props.computorChildrenDivs}
        </div>
    );
  }
  public loadMobilePage(): React.ReactNode {
    return (
      <div>
        {this.props.phoneChildrenDivs}
        </div>
    );
  }

  render() {
    if (ZJDeviceChecker.isMobile()) {
      return this.loadMobilePage();
    } else {
      return this.loadComputorPage();
    }
  }
}

export default DifferentDeviceFrame;
