import axios from "axios"
import ZJAdminDataManager from "../ZJAdminDataManager/ZJAdminDataManager"

interface ZJAdminLogoutButtonProp {
    callBackFun: Function
}

const ZJAdminLogoutButton: React.FC<ZJAdminLogoutButtonProp> = ({ callBackFun }) => {
    let logoutAction = () => {
        let token = ZJAdminDataManager.getAdminToken()
        if (token != null && token.length > 0) {
            let data = { "token": ZJAdminDataManager.getAdminToken() };
            axios
                .post("/api/admin_login/logout", data)
                .then(
                    response => {
                        if (response.status === 200) {
                            if (response.data && response.data.status && response.data.status === "0") {
                                return true
                            }
                        }
                        return false
                    }, error => {
                    }
                ).then(logoutSuccess => {
                    // 不管是否联网成功，都退出登录，删除本地token
                    if (callBackFun != null) {
                        ZJAdminDataManager.removeAdminToken()
                        callBackFun()
                    }
                })
        } else {
            if (callBackFun != null) {
                ZJAdminDataManager.removeAdminToken()
                callBackFun()
            }
        }
    }
    return <button
        onClick={() => {
            logoutAction()
        }}>
        退出登录
    </button>
}

export default ZJAdminLogoutButton