export default class LCBase64Tool {
    public static base64ToUTF8String (value : string) {
        if (value != null && value.length > 0) {
            return decodeURIComponent(window.atob(value))
        } 
        return ""
    }
    public static utf8StringToBase64(value: string) {
        if (value != null && value.length > 0) {
            return window.btoa(encodeURIComponent(value))
        } 
        return ""
    }
}