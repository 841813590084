import axios from "axios";

export default class LCCodePerformTool {
    static python: string = "python"
    static cplusplus: string = "c++"
    public static performCode(language: string, codeValue: string, callBack: Function) {
        if (language === this.python) {
            this.performPythonCode(codeValue, callBack)
        } else if (language === this.cplusplus) {
            this.performCPlusPlusCode(codeValue, callBack)
        } else {
            callBack("-6", "language param is wrong")
        }
    }
    static performPythonCode(codeValue: string, callBack: Function) {
        this.sendRequest(this.python, codeValue, callBack)
    }

    static performCPlusPlusCode(codeValue: string, callBack: Function) {
        this.sendRequest(this.cplusplus, codeValue, callBack)
    }

    static sendRequest(language: string, codeValue: string, callBack:Function) {
        if (!codeValue || codeValue.length === 0) {
            if (callBack) {
                callBack("-2", "code is null")
            }
        }
        let requestUrl: string = ""
        if (language === "python") {
            requestUrl = "https://hongxinyouzi.com/run_python"
        } else if (language === "c++") {
            requestUrl = "https://hongxinyouzi.com/run_cpp"
        } else {
            if (callBack) {
                callBack("-1", "fail")
            }
        }
        let codeBase64 = btoa(codeValue)
        if (!codeBase64 || codeBase64.length === 0) {
            if (callBack) {
                callBack("-3", "code base64 is null")
            }
        }
        axios.post(
            requestUrl, 
            {code: codeBase64}
          )
          .then(function (response) {
            if(callBack) {
                let serverStatus = response.data.status;
                if (response.status === 200 && parseInt(serverStatus) >= 0) {
                    //response.data.content 为空，说明执行成功，但是没有任何log输出
                    callBack(serverStatus, response.data.content)
                } else {
                    callBack("-6", "error occur in server")
                }
            }
          })
          .catch(function (error) {
            if (callBack) {
                callBack("-5", "error occur.")
            }
          });
        
    }
}