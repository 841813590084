

type ZJPreviewSingleImageProps = {
    url: string;
    deleteAction: Function;
    children?: React.ReactNode;
};

function ZJPreviewSingleImage(props: ZJPreviewSingleImageProps) {
    return (
        <div className="zj_preview_single_image_container">
            <img src={ props.url } alt=""/>
            <br/>
            <button 
            onClick={ ()=> {
                if (props.deleteAction != null) {
                    props.deleteAction(props.url);
                }
            } }>删除图片
            </button>
            <br/>
            <button 
            onClick={ ()=> {
                navigator.clipboard.writeText(props.url)
            } }>复制图片链接
            </button>
        </div>
    );
}

export default ZJPreviewSingleImage;