
class ZJDeviceChecker {
    public static isMobile(): boolean {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|wxwork)/i
        );
        if (flag != null) {
            return true;
        }
        return false;
    }
}

export default ZJDeviceChecker;