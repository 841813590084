

export default class ZJAdminDataManager {
    public static loginStatus: string = "0" // "0"  "1"
    public static loginToken: string = ""
    private static adminTokenKey = "admin_token_key"
    private static adminTokenSetTimeKey = "admin_token_set_time_key"

    public static getAdminToken() {
        let token = localStorage.getItem(this.adminTokenKey)
        // let tokenSetTime = localStorage.getItem(this.adminTokenSetTimeKey)
        if (token && token.length > 0) {
            // 设置sessioni及过期时间
            document.cookie = "JSSESSIONID=" + token;
            return token;
        } else {
            document.cookie = "JSSESSIONID=; max-age=0";
            return null;
        }
    }
    public static saveAdminToken(token: string) {
        if (token && token.length > 0) {
            localStorage.setItem(this.adminTokenKey, token)
            localStorage.setItem(this.adminTokenSetTimeKey, Date.now() / 1000 + "")
        }
    }
    public static removeAdminToken() {
        localStorage.removeItem(this.adminTokenKey)
    }

}
