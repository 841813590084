import { Carousel } from "antd"
import './AppHomeCarousel.css'
import ZJDeviceChecker from "../Tools/ZJDeviceChecker"


const AppHomeCarousel = function () {
    const loadImageContainerClassName = function() {
        if (ZJDeviceChecker.isMobile()) {
            return "app_home_page_top_carousel_mobile"
        } else {
            return "app_home_page_top_carousel_computor"
        }
    }
    return (
        <div className={loadImageContainerClassName()}>
            <Carousel arrows infinite={true} autoplay={true}>
                <div>
                    <img src="https://hongxinyouzi.com/learncoding/app_home_carousel_1.jpg" alt="" />
                </div>
                <div>
                    <img src="https://hongxinyouzi.com/learncoding/app_home_carousel_2.jpg" alt="" />
                </div>
                <div>
                    <img src="https://hongxinyouzi.com/learncoding/app_home_carousel_3.jpg" alt="" />
                </div>
            </Carousel>
        </div>
    )
}

export default AppHomeCarousel