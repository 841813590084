import { useState } from "react"

/**
* 注册完成后，直接跳到登录页面即可
 */
const LCUserSignUpPage: React.FC = () => {
    const [userName, setUserName] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [passwordVerified, setPasswordVerified] = useState("")
    const [phoneVerificationCode, setPhoneVerificationCode] = useState("")
    const [twoPasswordsSameState, setTwoPasswordsSameState] = useState(true)

    const signUpAction = () => {

    }

    return (
        <div className="main_content_container">
            <span>用户昵称:</span> 
            <input placeholder="请输入要使用的用户名"
                value={userName}
                onChange={(e) => {
                    setUserName(e.target.value)
                }} /> <br />
            <span>电话：</span><input
                placeholder="请输入电话号码"
                value={phone}
                onChange={(e) => {
                    setPhone(e.target.value)
                }} />
            <button>点击发送验证码</button>
            <br />
            <span>验证码:</span>
            <input type="text" 
            value={phoneVerificationCode}
            onChange={(e)=> {
                setPhoneVerificationCode(e.target.value)
            }}/>
            <br />
            <span>密码：</span>
             <input 
             placeholder="请输入密码" 
             value={password}
             type="password"
             onChange={(e) => {
                setPassword(e.target.value)
             }} /> <br />
            <span>再次输入密码并确认密码:</span> 
            <input 
            placeholder="请再次输入密码"
            type="password"
            value={passwordVerified}
            onChange={(e) => {
                setPasswordVerified(e.target.value)
            }}/> <br />
            <button
            onClick={() => {
                signUpAction()
            }}>注册</button>

        </div>
    )
}

export default LCUserSignUpPage