import React, { ReactNode } from "react";
import "./ZJTextEditor.css"
import ZJTextEditorTool from "./ZJTextEditorTool";

interface ZJEditingDataProps {
    contentString: string;
    previewMode: string;
    onChangeCallBack:Function;
    children?: ReactNode;
}

type ZJEditingStateType = {
    previewDivContentArray: JSX.Element[];
  };

class ZJTextEditor extends React.Component<ZJEditingDataProps, ZJEditingStateType> {
    constructor(props: ZJEditingDataProps) {
        super(props);
        this.state = { previewDivContentArray: [] };
    }
    render() {
        const { contentString, previewMode } = this.props;
        return (
            <div id="zj_text_editor">
                     {/* {ZJTextEditorTool.transformRawStringToDivArray(contentString)} 外层必须是div*/} 
                     <textarea 
                     id="zj_text_area" 
                     defaultValue={contentString}
                     style={ previewMode === "1" ? {width: "50%"} : {width: "100%"}}
                     onChange={(e) => {
                        let textAreaContent = e.target?.value;
                        if (textAreaContent == null) {
                            return;
                        }
                        let contentArray = ZJTextEditorTool.transformRawStringToDivArray(textAreaContent);
                        if (contentArray != null 
                            && contentArray.length > 0) {
                            this.setState({
                                previewDivContentArray: contentArray
                            });
                        }
                        if (this.props.onChangeCallBack != null) {
                            this.props.onChangeCallBack(textAreaContent);
                        }
                     }}/>
                     <div 
                     id="zj_editor_preview"
                     style={ previewMode === "1" ? {width: "49%"} : {width: "0%"}}>
                        {this.state.previewDivContentArray}
                     </div>
                     
            </div>
        );
    }
}

export default ZJTextEditor
