

export default class LCUserTokenManager {
    public static loginStatus: string = "0" // "0"  "1"
    public static loginToken: string = ""
    private static userTokenKey = "user_token_key"

    public static getUserToken() {
        let token = localStorage.getItem(this.userTokenKey)
        if (token && token.length > 0) {
            // 设置sessioni及过期时间
            document.cookie = "JSSESSIONID=" + token;
            return token;
        } else {
            document.cookie = "JSSESSIONID=; max-age=0";
            return null;
        }
    }
    public static saveUserToken(token: string) {
        if (token && token.length > 0) {
            localStorage.setItem(this.userTokenKey, token)
        }
    }
    public static removeUserToken() {
        localStorage.removeItem(this.userTokenKey)
    }

    public static userLoginState() {
        let token = localStorage.getItem(this.userTokenKey)
        if (token && token.length > 0) {
            return true;
        }
        return false;
    }
}