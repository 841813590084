import LCUserAccountComponent from "../LCUserAccount/LCUserAccountComponent/LCUserAccountComponent";
import ZJPageFrame from "../ZJPageFrame/ZJPageFrame";

const LCMinePage = function () {
  return (
    <div className="main_content_container">
      <ZJPageFrame
        index={2}>
        <div>
          <LCUserAccountComponent />
        </div>
      </ZJPageFrame>
    </div>
  )
}

export default LCMinePage;