import LCRouteManager from "../../LCRouter/LCRouteManager"

class NavigationBarParams {
    public static naviHomePage = "首页"
    public static naviHomePageHref = LCRouteManager.AppHomePageUrlPath
    public static naviClasses = "课程"
    public static naviClassesHref = LCRouteManager.LCAllLessonsPageUrlPath
    public static naviMine = "我的"
    public static naviMineHref = LCRouteManager.LCMinePageUrlPath
}

class HomePageConfigs {
    public static homePageIndex: number = 0;
    public static classPageIndex: number = 1;
    public static minePageIndex: number = 2;
}

class UrlPathConfi {

}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    HomePageConfigs,
    NavigationBarParams,
    UrlPathConfi
}