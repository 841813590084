import axios from "axios";
import LCUserTokenManager from "../LCData/LCUserTokenManager";
import { useEffect, useState } from "react";
import ZJTextEditorTool from "../../Components/ZJTextEditor/ZJTextEditorTool";

/**
 * 访问地址为：
 * http://localhost:3000/article_content_page?article_id=2
 */
const LCArticleContentPage: React.FC = () => {
    let params = new URLSearchParams(window.location.search);
    let articleID = params.get("article_id")
    const [articleContentString, setArticleContentString] = useState("")

    useEffect(() => {
        const sendArticleContentRequest = () => {
            axios.post("api/article/select_one_article",
                {
                    id: articleID,
                    token: LCUserTokenManager.getUserToken()
                })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.status && response.data.status === "0") {
                            return response.data.data
                        }
                    }
                    return null
                }, error => {
                    return null
                }).then(articleContent => {
                    if (articleContent) {
                        let articleObj = JSON.parse(articleContent)
                        setArticleContentString(articleObj['content'])
                    }
                })
        }
        sendArticleContentRequest()
    }, [articleID]);

    return (
        <div className="main_content_container">
            {ZJTextEditorTool.transformRawStringToDivArray(articleContentString)}
        </div>
    )
}

export default LCArticleContentPage