import axios from "axios";
import LCBase64Tool from "../../Tools/LCBase64Tool";
import LCUserTokenManager from "../../LCData/LCUserTokenManager";

export default class LCUserAccountHelper {
    public static jumpToLastPageAfterLoginAndLogout() {
        let params = new URLSearchParams(window.location.search);
        if (params) {
            let lastPageUrl = params.get("last_page_url");
            if (lastPageUrl && lastPageUrl.length > 0) {
                lastPageUrl = LCBase64Tool.base64ToUTF8String(lastPageUrl)
                if (lastPageUrl && lastPageUrl.length > 0) {
                    window.location.href = lastPageUrl
                    return
                }
            }
        }
        // 如果没有获取到 last page url 跳转到首页
        window.location.href = "/"
    }

    // 登录成功，那么回调true,否则false
    /**
     * NOTE Peter, asyn 刚好是同步，但是可以使用.then语法
     */
    public static sendLoginRequest(userPhone: string, userPassword: string) {
        return new Promise(function (resolve, reject) {
            let data = { phone: userPhone, password: userPassword }
            axios.post("/api/users/login", data)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.status && response.data.status === "0") {
                            return response.data.session
                        }
                    }
                    return null
                }, error => {
                    return null
                })
                .then(session => {
                    if (session != null && session.length > 0) {
                        LCUserTokenManager.saveUserToken(session)
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
        });
    }

    public static sendInviteLoginRequest(userPhone: string, userPassword: string) {
        return new Promise(function (resolve, reject) {
            let data = { phone: userPhone, password: userPassword }
            axios.post("/api/invite_users/login", data)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.status && response.data.status === "0") {
                            return response.data.session
                        }
                    }
                    return null
                }, error => {
                    return null
                })
                .then(session => {
                    if (session != null && session.length > 0) {
                        LCUserTokenManager.saveUserToken(session)
                        resolve(true)
                    } else {
                        reject(false)
                    }
                })
        });
    }

    public static sendLogoutRequest() {
        return new Promise(function (resolve, reject) {
            axios.post("/api/users/logout", { token: LCUserTokenManager.getUserToken() })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data.status && response.data.status === "0") {
                            return true
                        }
                    }
                    return false
                }, error => {
                    return false
                })
                .then(success => {
                    if (success) {
                        LCUserTokenManager.removeUserToken()
                        resolve(true)
                    } else {
                        reject(new Error("logout fail error"))
                    }
                })
        });
    }
}