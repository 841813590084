import LCUserAccountHelper from "../LCUserAccountHelper/LCUserAccountHelper"



const LCUserLogoutButton = () => {
    const logoutAction = () => {
        LCUserAccountHelper.sendLogoutRequest()
        .then(success => {
            if (success) {
                window.location.reload()
            } else {
                alert("退出登录失败")
            }
        })
    }
    return (
        <button onClick={() => {
            logoutAction()
        }}>
            退出登录
        </button>
    )
}

export default LCUserLogoutButton