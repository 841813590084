import ZJPageFrame from "../../ZJPageFrame/ZJPageFrame";


const LCAllLessonsPage = function () {
  return (
    <div className="main_content_container">
      <ZJPageFrame
        index={1}>
        <div>
          all classes page, comming soon
        </div>
      </ZJPageFrame>
    </div>
  )
}

export default LCAllLessonsPage;