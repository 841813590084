import './LCWebsiteInfoBar.css'

const LCWebsiteInfoBar = function() {
    return (
        <div id="lc_website_info_bar">
            <div>
                <p>Copyright © 2022
                    <span>
                        &nbsp;&nbsp;&nbsp;
                        <a href={"https://hongxinyouzi.com"}>
                            红心柚子网
                        </a>
                        &nbsp;&nbsp;&nbsp;
                    </span>
                    All Rights Reserved
                </p>
                <p>
                    <a href='https://beian.miit.gov.cn/#/Integrated/index'>
                        网站备案号：京ICP备15015712号-2
                    </a>
                </p>
            </div>
        </div>
    )
}

export default LCWebsiteInfoBar