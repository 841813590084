import React from "react";
import './ZJComputorNavigationBar.css'
import ZJConfigs from "../Config/ZJConfigs";

interface Props {
  index: number;
}
const ZJComputorNavigationBar: React.FC<Props> = ({ index }) => {
  const commonClassName = "zj_computor_navigation_bar_item_common"
  const normalClassName = "zj_computor_navigation_bar_item_normal"
  const selectedClassName = "zj_computor_navigation_bar_item_selected"

  const getAClassName = function(index: number, currentIndex: number): string {
    const selected = index === currentIndex ? selectedClassName : normalClassName;
    return commonClassName + " " + selected;
  }
  return (
    <div>
      <div id="zj_computor_navigation_bar">
        <div id="zj_computor_navigation_bar_container">
          <a className={getAClassName(index, 0)}
            href={ZJConfigs.NavigationBarParams.naviHomePageHref}>
            {ZJConfigs.NavigationBarParams.naviHomePage}</a>
          <a className={getAClassName(index, 1)}
            href={ZJConfigs.NavigationBarParams.naviClassesHref}>
            {ZJConfigs.NavigationBarParams.naviClasses}</a>
          <a className={getAClassName(index, 2)}
            href={ZJConfigs.NavigationBarParams.naviMineHref}>
            {ZJConfigs.NavigationBarParams.naviMine}</a>
        </div>
      </div>
      <div id="zj_computor_navigation_bar_bottom_line"></div>
    </div>
  );
};

export default ZJComputorNavigationBar;