import axios from "axios"
import ZJAdminDataManager from "../ZJAdminDataManager/ZJAdminDataManager"

interface ZJDeleteArticleButtonProp {
    articleID: string
    deleteSuccessCallBack: Function,
    failToDeleteCallBack: Function
}

const ZJDeleteArticleButton: React.FC<ZJDeleteArticleButtonProp> = function ({ articleID, deleteSuccessCallBack, failToDeleteCallBack }) {
    const deleteAction = () => {
        axios.post("/api/article/delete_article", {
            id: articleID,
            token: ZJAdminDataManager.getAdminToken()
          })
            .then(
                response => {
                    if (response.status === 200) {
                        if (response.data && response.data.status && response.data.status === "0") {
                            return true
                        }
                    }
                    return false
                }, error => {
                    return true
                }
            ).then(success => {
                if (success) {
                    deleteSuccessCallBack()
                } else {
                    failToDeleteCallBack()
                }
            })
    }
    return (
        <button onClick={() => {
            deleteAction()
        }}>删除按钮</button>
    )
}

export default ZJDeleteArticleButton