

const LCScratchLessonsPage = function() {
    return (
    <div>
        this is scratch lesson page

    </div>);
}

export default LCScratchLessonsPage;