import { useState } from 'react';
import ZJTextEditor from '../../../Components/ZJTextEditor/ZJTextEditor';
import './ZJAddArticlePage.css'
import axios from 'axios';
import ZJAdminDataManager from '../ZJAdminDataManager/ZJAdminDataManager';
import ZJImageEditor from '../../../Components/ZJImageEditor/ZJImageEditor';
import ZJImageFileToBase64Tool from '../../../Components/ZJImageEditor/ZJImageFileToBase64Tool';
import LCFileDownloader from '../../../UserInterface/Tools/LCFileDownloader';

interface ZJAddArticlePageProps {
    show: boolean;
    finishAddFun: Function
}

const ZJAddArticlePage: React.FC<ZJAddArticlePageProps> = ({ show, finishAddFun }) => {
    const contentInit = ""
    const [articleTitle, setArticleTitle] = useState("")
    const [articleContent, setArticleContent] = useState("")

    const saveArticle = () => {
        if (articleTitle != null
            && articleTitle.length > 0
            && articleContent != null
            && articleContent.length > 0) {
            let data = {
                title: articleTitle,
                content: articleContent,
                token: ZJAdminDataManager.getAdminToken()
            }
            axios.post(
                "/api/article/add_new_article",
                data
            ).then(response => {
                if (response.status === 200) {
                    if (response.data && response.data.status && response.data.status === "0") {
                        return true
                    }
                }
                return false
            }, error => {
                return false
            }).then(success => {
                if (success) {
                    if (finishAddFun) {
                        finishAddFun();
                    }
                } else {
                    alert("保存失败，请重试")
                }
            })
        }
    }
    return (
        <div id="zj_add_article_page"
            style={{ display: (show ? "block" : "none") }}>
            {/* add page
            <button onClick={()=> {
                if (finishAddFun) {
                    finishAddFun()
                }
            }}>完成添加文章</button> */}
            <br />
            <div>
                文章编辑页
            </div>
            <div>
                标题栏，请输入
            </div>
            <div>
                <input
                    placeholder='标题'
                    onChange={(event) => {
                        if (event.target.value) {
                            setArticleTitle(event.target.value);
                        }
                    }} />
            </div>
            <br />
            <div>
            <div>image处理器</div>
            <ZJImageEditor
                uploadAction={(file: File, finishBlock: Function) => {
                    ZJImageFileToBase64Tool(file)
                        .then(base64String => {
                            LCFileDownloader.instance.uploadImage(base64String as string, file.name, (url: string) => {
                                if (finishBlock != null) {
                                    finishBlock(url);
                                }
                            })
                        }, error => {
                            return ""
                        })
                }}>
            </ZJImageEditor>
            </div>

            <br/>
            <div>
                <div>
                    <ZJTextEditor
                        contentString={contentInit}
                        previewMode={"1"}
                        onChangeCallBack={(value: string) => {
                            setArticleContent(value)
                        }} />
                </div>
            </div>
            <div>
                <button onClick={() => {
                    saveArticle()
                }}>保存文章</button>
            </div>
        </div>
    )
}

export default ZJAddArticlePage