import React from 'react';

import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/addon/display/autorefresh'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/3024-night.css';
import 'codemirror/mode/clike/clike';
import './LCCPlusPlusEditor.css'


interface LCCPlusPlusEditorProps {
    codeValue: string;
    updateCallBack: Function
}
const LCCPlusPlusEditor: React.FC<LCCPlusPlusEditorProps> = ({ codeValue, updateCallBack }) => {
    setTimeout(() => {
        let array = document.getElementsByClassName("CodeMirror");
        if (array !== undefined && array.length > 1) {
            let secondOne = array[1]
            secondOne.parentNode?.removeChild(secondOne)
        }
    }, 500);
    return (
        <div id='lc_cplus_plus_editor_container'>
            <CodeMirror
                value={codeValue}
                options={{
                    mode: 'text/x-c++src',
                    theme: 'material',
                    lineNumbers: true,
                    // smartIndent: true,
                    indentUnit: 4, // 智能缩进单位为4个空格长度
                    lineWrapping: true, // 在行槽中添加行号显示器、折叠器、语法检测器
                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"], foldGutter: true, // 启用行槽中的代码折叠
                    autofocus: true, // 自动聚焦
                    styleActiveLine: true, // 显示选中行的样式
                    autoRefresh: true
                }}
                onUpdate={(editor)=>{
                    let value = editor.getValue()
                    if (updateCallBack) {
                        updateCallBack(value)
                    }
                }}
            />
        </div>);
}

export default LCCPlusPlusEditor;