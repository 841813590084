import React from "react";
import './ZJPhoneNavigationBar.css'
import ZJConfigs from "../Config/ZJConfigs";


interface ZJPhoneNavigationBarProps {
  index: number;
}
const ZJPhoneNavigationBar: React.FC<ZJPhoneNavigationBarProps> = ({ index }) => {
  const itemCommonClassName: string = "zj_phone_navigation_bar_item_common"
  const itemNormalClassName: string = "zj_phone_navigation_bar_item_normal"
  const itemSelectedClassName: string = "zj_phone_navigation_bar_item_selected"

  const itemClassName = function (index: number, itemIndex: number): string {
    const selectedClassName: string = index === itemIndex ? itemSelectedClassName : itemNormalClassName;
    // NOTE PETER 加上{}代表一个object
    return itemCommonClassName + " " + selectedClassName;
  }
  return (
    <div id="zj_phone_navigation_bar_container">
      <div className={itemClassName(index, ZJConfigs.HomePageConfigs.homePageIndex)}>
        <a href={ZJConfigs.NavigationBarParams.naviHomePageHref}>{ZJConfigs.NavigationBarParams.naviHomePage}</a>
      </div>
      <div className={itemClassName(index, ZJConfigs.HomePageConfigs.classPageIndex)}>
        <a href={ZJConfigs.NavigationBarParams.naviClassesHref}>{ZJConfigs.NavigationBarParams.naviClasses}</a>
      </div>
      <div className={itemClassName(index, ZJConfigs.HomePageConfigs.minePageIndex)}>
        <a href={ZJConfigs.NavigationBarParams.naviMineHref}>{ZJConfigs.NavigationBarParams.naviMine}</a>
      </div>
    </div>
  );
};

export default ZJPhoneNavigationBar;