
import ZJPageFrame from "../ZJPageFrame/ZJPageFrame";
import AppHomeCarousel from "./AppHomeCarousel";

const AppHomePage = function () {
  return (
    <div className="main_content_container">
      <ZJPageFrame
        index={0}>
        <div>
          <AppHomeCarousel />
          <div>
            为什么学习编程
          </div>
          <div>
            编程是什么
          </div>
          <div>
            如何学习编程
          </div>
          <div>
            本站课程介绍
          </div>
          <div>
            如何开启本站的学习之路
          </div>
        </div>
      </ZJPageFrame>
    </div>

  )
}

export default AppHomePage;
