import LCUserTokenManager from "../../LCData/LCUserTokenManager";
import LCUserLoginButton from "../LCUserLoginButton/LCUserLoginButton";
import LCUserLogoutButton from "../LCUserLogoutButton/LCUserLogoutButton";
import './LCUserAccountComponent.css'

const LCUserAccountComponent = () => {
    let currentPath = window.location.pathname
    const accountChildrenDiv = () => {
        let login = LCUserTokenManager.userLoginState()
        if (login) {
            return (
                <div className="acount_page_container">
                    <p>您好，欢迎回来!</p>
                    您已经登录，<LCUserLogoutButton />
                </div>
            )
        } else {
            return (
                <div className="acount_page_container">
                    <p>尚未登录 <LCUserLoginButton currentUrl={currentPath}>请先登录</LCUserLoginButton></p> 
                    <p>或 <LCUserLoginButton currentUrl={currentPath}>使用邀请码体验所有课程</LCUserLoginButton></p>
                </div>
            )
        }
    }
    return (
        <div>
            { accountChildrenDiv() }
        </div>
    )
}

export default LCUserAccountComponent;