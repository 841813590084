
import axios from "axios";
import LCDataManager from "../LCData/LCDataManager";
import ZJAdminDataManager from "../../AdminPage/AdminArticle/ZJAdminDataManager/ZJAdminDataManager";


export default class LCFileDownloader {
    static instance: LCFileDownloader = new LCFileDownloader()
    downloadFile(url: string, callBack: Function) {
        const successStatus = "0"
        const decoder = new TextDecoder('utf-8');
        const downloadUrl = url;
        // use cache
        let result = LCDataManager.requestUrlSingletonMap.get(url);
        if (result != null){
            if (callBack) {
                callBack({
                    statusKey: successStatus,
                    contentKey: result
                });
            }
            return;
        }
    
        let duringRequest = LCDataManager.requestUrlDuringRequestSingletonMap.get(url)
        if (duringRequest != null) {
            // 请求中，直接返回
            return;
        }
        LCDataManager.requestUrlDuringRequestSingletonMap.set(url, "during_request");
        fetch(downloadUrl, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then((response) => {
            if (response != null && response.body != null) {
                const reader = response.body.getReader();
                reader.read().then((arrayBufferData) => {
                    if (callBack != null) {
                        let finish: Boolean = false
                        const result = decoder.decode(arrayBufferData.value);
                        if (result != null) {
                            const base64Result = btoa(result);
                            if (base64Result != null) {
                                finish = true
    
                                callBack({
                                    statusKey: successStatus,
                                    contentKey: result
                                });
                                // 设置一小时缓存
                                LCDataManager.requestUrlSingletonMap.set(url, result);
                                setTimeout(() => {
                                    LCDataManager.requestUrlSingletonMap.delete(url);
                                }, 1 * 60 * 60 * 1000);
                                LCDataManager.requestUrlDuringRequestSingletonMap.delete(url);
                            }
                        }
                        if (finish === false) {
                            callBack({
                                statusKey: "-1",
                                contentKey: "result is null"
                            });
                            LCDataManager.requestUrlDuringRequestSingletonMap.delete(url);
                        }
                    }
    
                })
            } else {
                if (callBack != null) {
                    callBack({
                        statusKey: "-2",
                        contentKey: "url may is null, did get response"
                    });
                    LCDataManager.requestUrlDuringRequestSingletonMap.delete(url);
                }
            }
        }).catch((error) => {
            if (callBack != null) {
                callBack({
                    statusKey: "-3",
                    contentKey: "error occur."
                });
                LCDataManager.requestUrlDuringRequestSingletonMap.delete(url);
            }
        });
    }

    uploadImage(base64String: string, imageName: string, callBack: Function) {
        let token = ZJAdminDataManager.getAdminToken()
        let data = {
            token: token ? token : "",
            imageBase64: base64String ? base64String : "",
            imageName: imageName ? imageName : ""
        }
        axios
        .post("/api/images/upload", data)
        .then(response => {
            if (response.status === 200) {
                if (response.data && response.data.status && response.data.status === "0") {
                    return response.data.imageUrl
                }
            }
            return null
        }, error => {
            return null
        })
        .then(imageUrl => {
            if (callBack) {
                callBack(imageUrl)
            }
        })
    }

    sendDeleteImageRequest(imageUrl: string) {
        let token = ZJAdminDataManager.getAdminToken()
        let data = {
            token: token ? token : "",
            imageName: imageUrl
        }
        axios
        .post("/api/images/delete_image", data)
        .then(response => {
            if (response.status === 200) {
                if (response.data && response.data.status && response.data.status === "0") {
                    return true
                }
            }
            return false
        }, error => {
            return false
        })
        .then(result => {
            // if (result) {
            //     alert("删除成功")
            // } else {
            //     alert("删除失败")
            // }
        })
    }
}
