

class ZJTextEditorTool {
    public videoType: string = "video";
    public imageType: string = "image";
    public stringType: string = "string";
    public static transformTextAreaContentToMapArray(value: string) {
        if (value === null) {
            return null;
        }
        let array = value.split('\n')
        let desArray = [];
        for (var i = 0; i < array.length; i++) {
            let eachString: string = array[i] as string;
            let preString = "";
            while (eachString?.startsWith(" ")) {
                eachString = eachString.substring(1, value.length);
                // note peter React显示空格
                preString = preString + '\u00A0';
            }
            if (eachString != null) {
                let aLabelMap = this.parseHtmlALabel(eachString, preString);
                if (aLabelMap != null && aLabelMap?.size > 0) {
                    desArray.push(aLabelMap);
                    continue;
                }
                let imageLabelMap = this.parseImageLabel(eachString, preString);
                if (imageLabelMap != null && imageLabelMap?.size > 0) {
                    desArray.push(imageLabelMap);
                    continue;
                }

                let videoLabelMap = this.parseVideoLabel(eachString, preString);
                if (videoLabelMap != null && videoLabelMap?.size > 0) {
                    desArray.push(videoLabelMap);
                    continue;
                }

                let map = new Map<String, String>();
                map.set("type", "string");
                map.set("content", eachString);
                if (preString.length > 0) {
                    map.set("pre_string", preString);
                }
                desArray.push(map);
            }
        }
        return desArray;
    }

    private static parseHtmlALabel(value: string, preString: string) {
        let map = new Map<String, String>();
        if (value != null && value.startsWith("<a")) {
            {
                let reges: RegExp = /(?<=<a.*href=")([^"]*)(?=")/g;
                const typeResult = value.match(reges);
                if (typeResult != null && typeResult.length > 0) {
                    map.set("href", typeResult[0]);
                }
            }
            {
                let reges: RegExp = /(?<=<a.*>)(.*(?=<))/g;
                const typeResult = value.match(reges);
                if (typeResult != null && typeResult.length > 0) {
                    map.set("content", typeResult[0])
                }
            }
            if (map.size > 0) {
                map.set("type", "a");
                if (preString.length > 0) {
                    map.set("pre_string", preString);
                }
            }
        }
        return map.size > 0 ? map : null;
    }

    private static parseImageLabel(value: string, preString: string) {
        let map = new Map<String, String>();
        if (value != null && value.startsWith("<image")) {
            {
                let reges: RegExp = /(?<=<image.*src=")([^"]*)(?=")/g;
                const typeResult = value.match(reges);
                if (typeResult != null && typeResult.length > 0) {
                    map.set("src", typeResult[0])
                }
            }
            if (map.size > 0) {
                map.set("type", "image");
            }
        }
        return map.size > 0 ? map : null;
    }

    /**
     * 使用抖音做视频分享，视频需要拍 16/9的
           <iframe width="810" height="590" frameborder="0" src="https://open.douyin.com/player/video?vid=7432677182540909836&autoplay=1" referrerpolicy="unsafe-url" allowfullscreen></iframe>
     * 
     * iframe需要base64之后在解码
     */
    private static parseVideoLabel(value: string, preString: string) {
        let map = new Map<String, String>();
        if (value != null && value.startsWith("<video")) {
            {
                let reges: RegExp = /(?<=<video.*src=")([^"]*)(?=")/g;
                const typeResult = value.match(reges);
                if (typeResult != null && typeResult.length > 0) {
                    map.set("src", typeResult[0])
                }
            }
            if (map.size > 0) {
                map.set("type", "video");
            }
        }
        return map.size > 0 ? map : null;
    }

    public static transforMapArrayToDiv(array: Array<Map<String, String>>) {
        let desArray = [];
        for (let i = 0; i < array.length; i++) {
            let eachMap = array[i];
            let type = eachMap.get("type");
            let preString = eachMap.get("pre_string");
            if (type != null) {
                if (type === "string") {
                    let value = eachMap.get("content");
                    let desLabel = (<div key={i}>{preString}{value}</div>);
                    desArray.push(desLabel);
                } else if (type === "image") {
                    let srcValue = eachMap.get("src");
                    let srcFinalValue: string = srcValue + "";
                    let desImage = (
                        <div key={i} className="zj_editor_image_container">
                            <img src={srcFinalValue} alt="" className="zj_editor_inner_image" />
                        </div>
                    );
                    desArray.push(desImage);
                } else if (type === "video") {
                    let srcValue = eachMap.get("src");
                    let srcFinalValue: string = srcValue + "";
                    let contentList = document.getElementsByClassName("main_content_container");
                    let widthNum = 0;
                    let heightNum = 0;
                    if (contentList != null && contentList.length > 0) {
                        widthNum = contentList[0].clientWidth;
                        widthNum = widthNum * 0.9;
                        heightNum = widthNum * 490 / 810;
                    }
                    let widthJustNum = Number.parseInt(widthNum + "")
                    let heightJustNum = Number.parseInt(heightNum + "")


                    let desVideo = (
                    <div 
                    key={i} 
                    className="zj_editor_video_container"
                    style={{backgroundColor: "#AA0000", width:widthJustNum, height:heightJustNum}}
                    >
                            <iframe className="zj_editor_douyin_video_iframe" width={widthJustNum} height={heightJustNum} title="my_video" referrerPolicy="unsafe-url" src={srcFinalValue}></iframe>
                            {/* "https://open.douyin.com/player/video?vid=7432677182540909836&autoplay=1" */}
                            {/* <iframe className="zj_editor_bilibili_video_iframe" width={810} height={491} src={srcFinalValue} allowFullScreen title="bilibli_video"></iframe> */}
                    </div>);
                    desArray.push(desVideo);
                } else if (type === "a") {
                    let hrefValue = eachMap.get("href");
                    let contentValue = eachMap.get("content");
                    desArray.push(<div key={i}>{preString}<a target="_blank" rel="noreferrer" href={hrefValue + ""}>{contentValue}</a></div>)
                }
            }
        }
        return desArray;
    }

    public static transformRawStringToDivArray(content: string) {
        let array = this.transformTextAreaContentToMapArray(content);
        if (array != null) {
            let result = this.transforMapArrayToDiv(array);
            return result;
        } else {
            let array = []
            array.push(<div></div>)
            return array;
        }
    }
}

export default ZJTextEditorTool