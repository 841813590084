import { useEffect, useState } from "react";
import './ZJImageEditor.css'
import ZJPreviewSingleImage from "./ZJPreviewSingleImage";
import LCFileDownloader from "../../UserInterface/Tools/LCFileDownloader";

/**
 使用举例
 <ZJImageEditor
      uploadAction={(file: File, finishBlock: Function)=>{
        // alert(file)
        if (finishBlock != null) {
          finishBlock("http://gips3.baidu.com/it/u=1821127123,1149655687&fm=3028&app=3028&f=JPEG&fmt=auto?w=720&h=1280");
        }
      }}>
      </ZJImageEditor>
 */
// 必须得定义props类型,之后传进去，否则会报错, 加？号可以有子节点，也可以没有
type ZJImageEditorProps = {
    uploadAction: Function;
    previewImageUrlArray?: Array<string>;
    children?: React.ReactNode;
};

function ZJImageEditor(props: ZJImageEditorProps) {
    let previewUrlArray: Array<string> = [];
    if (props.previewImageUrlArray != null) {
        for(let i = 0; i < props.previewImageUrlArray.length; i++) {
            previewUrlArray.push(props.previewImageUrlArray[i]);
        }
    }
    const [previewImageArray, setPreviewImageArray] = useState(Array<JSX.Element>);
    const finishUploadImage = function(url: string) {
        addImageAction(url);
    }

    const reloadAllImages = function() {
        let desImages: Array<JSX.Element> = [];
        for(let i = 0; i < previewUrlArray.length; i++) {
            let eachUrl = previewUrlArray[i];
            let eachSingleImage = (
                <ZJPreviewSingleImage 
                url={eachUrl} 
                deleteAction={(url: string)=>{
                    deleteOneImage(url);
                }}/>
            );
            desImages.push(eachSingleImage);
        }
        setPreviewImageArray(desImages);
    }

    useEffect(() => {
        reloadAllImages()
    }, [])

    const addImageAction = function(url: string) {
        if (url == null
            || url.length === 0) {
                return;
        }
        previewUrlArray.push(url);
        reloadAllImages();
    }

    const deleteOneImage = function(url: string) {
        let desArray: Array<string> = [];
        for(let i = 0; i < previewUrlArray.length; i++) {
            let each = previewUrlArray[i];
            if (each !== url) {
                desArray.push(each);
            }
        }
        while(previewUrlArray.length > 0) {
            previewUrlArray.pop();
        }
        for(let i = 0; i < desArray.length; i++) {
            let each = desArray[i];
            if (each !== url) {
                previewUrlArray.push(each);
            }
        }
        reloadAllImages();
        deleteImageRequest(url)
    }

    const deleteImageRequest = function(url: string) {
        LCFileDownloader.instance.sendDeleteImageRequest(url)
    }

    

    return (
        <div id="zj_image_editor">
            <div id="zj_image_uploader_container">

            </div>
            <div id="zj_image_selector">
                请选择文件，之后点击上传，开始上传Image文件:
                <br/>
                <br/>
                <input id="zj_image_uploader_input" type="file"/>
                <button onClick={ () => {
                    let fileInput = document.getElementById("zj_image_uploader_input") as HTMLInputElement;
                    if (props.uploadAction != null) {
                        let fileList = fileInput?.files;
                        if (fileList != null && fileList.length > 0) {
                            props.uploadAction(fileList[0], ((url: string)=>{
                                if (url != null) {
                                    finishUploadImage(url);
                                }
                            }));
                        } else {
                            alert("请选择图片");
                        }
                    }
                }}> 点击上传</button>
            </div>
            <div id="zj_image_previewer_container">
                {previewImageArray}
            </div>
        </div>
    )
    
}

export default ZJImageEditor;