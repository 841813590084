import React from "react";
import LCRouteManger from "../../../LCRouter/LCRouteManager"
import LCBase64Tool from "../../Tools/LCBase64Tool";

interface LCUserLoginButtonProps {
    currentUrl:string // 原页面路由
    children?:React.ReactNode;
}
const LCUserLoginButton: React.FC<LCUserLoginButtonProps> = ({currentUrl, children}) => {

    return (
        <button
            id="lc_user_login_button"
            onClick={() => {
                let desUrl = LCRouteManger.LCUserLoginPageUrlPath + "?last_page_url=" + LCBase64Tool.utf8StringToBase64(currentUrl);
                window.location.href = desUrl
            }}>
                {/* 如果有子节点，使用子节点，否则显示登录两个字 */}
               {children ? children : "登录"}
        </button>)
}

export default LCUserLoginButton