import React, { ReactElement } from "react";
import DifferentDeviceFrame from "../DifferentDeviceFrame/DifferentDeviceFrame";
import ZJComputorNavigationBar from "../NavigationBar/ZJComputorNavigationBar";
import ZJPhoneNavigationBar from "../NavigationBar/ZJPhoneNavigationBar";
import './ZJPageFrame.css'
import LCWebsiteInfoBar from "../LCWebsiteInfoBar/LCWebsiteInfoBar";

type ZJPageFrameType = {
    children: ReactElement;
    index: number
}

export default class ZJPageFrame extends React.Component<ZJPageFrameType> {
    render() {
        return (
            <div>
                <DifferentDeviceFrame
                    computorChildrenDivs={
                        <div id="computor_device_content_container">
                            <ZJComputorNavigationBar index={this.props.index}></ZJComputorNavigationBar>
                            <div>{this.props.children}</div>
                            <LCWebsiteInfoBar></LCWebsiteInfoBar>
                        </div>
                    }
                    phoneChildrenDivs={
                        <div id="mobile_device_content_container">
                            <div id="phone_main_content_container">{this.props.children}</div>
                            <ZJPhoneNavigationBar index={this.props.index}></ZJPhoneNavigationBar>
                        </div>
                    } />
            </div>
        )
    }
}