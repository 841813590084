

const ImageFileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        // 创建一个新的 FileReader 对象
        const reader = new FileReader();
        // 读取 File 对象
        reader.readAsDataURL(file);
        // 加载完成后
        reader.onload = function () {
            let base64String = ""
            // 将读取的数据转换为 base64 编码的字符串
            if (reader.result) {
                base64String = reader.result.toString()
                resolve(base64String);
            } else {
                reject("change to base64 fail")
            }
        };

        // 加载失败时
        reader.onerror = function () {
            reject("Failed to load file");
        };
    });
}

export default ImageFileToBase64