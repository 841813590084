

const LCCPlusPlusLessonsPage = () => {
    return (
        <div>
            c++ lessons
        </div>
    )
}

export default LCCPlusPlusLessonsPage