
import { useState } from "react";
import LCUserAccountHelper from "../LCUserAccountHelper/LCUserAccountHelper";
import './LCUserLoginPage.css'
import ZJPageFrame from "../../ZJPageFrame/ZJPageFrame";

/**
 * 登录界面跳转成功后，跳转到哪里是由登录登录的Button决定的，也就是last_page_url 这个query怎么写，就怎么跳
 * 
 */
const LCUserLoginPage: React.FC = () => {
    const goBackToLastPage = () => {
        LCUserAccountHelper.jumpToLastPageAfterLoginAndLogout()
    }

    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [invite, setInvite] = useState("")

    const sendLoginRequest = (thePhone: string, thePassword: string) => {
        LCUserAccountHelper.sendLoginRequest(thePhone, thePassword)
            .then(success => {
                if (success) {
                    alert("登录成功了")
                    goBackToLastPage()
                } else {
                    alert("登录失败，请重新尝试")
                }
            })
    }

    const sendInviteLoginRequest = (thePhone: string, thePassword: string) => {
        LCUserAccountHelper.sendInviteLoginRequest(thePhone, thePassword)
            .then(success => {
                if (success) {
                    alert("登录成功了")
                    goBackToLastPage()
                } else {
                    alert("登录失败，请重新尝试")
                }
            })
    }

    return (
        <ZJPageFrame index={2}>
            <div className="main_content_container">
                <div>
                    <div className="login_center_content_div">
                        输入邀请码，直接登录:
                        <div className="user_login_line_div">
                            <input className="user_login_user_input invite_login_input" type="password" value={invite} onChange={(e) => {
                                if (e && e.target && e.target.value) {
                                    setInvite(e.target.value)
                                } else {
                                    setInvite("")
                                }
                            }} />

                        </div>
                        <div className="user_login_line_div">
                            <button className="user_login_button invite_button" onClick={() => {
                                // 目前使用的邀请码是246810,没有安全性可言，完全是为了获取抖音客户
                                if (invite != null && invite.length > 0) {
                                    if(btoa(invite) === "MjQ2ODEw") {
                                        sendInviteLoginRequest("88888888", "abc_387891121")
                                    } else {
                                        alert("邀请码错误，请重新获取")
                                    }

                                } else {
                                    alert("请输入邀请码")
                                }
                            }}>使用邀请码免费使用全部教程</button>
                        </div>
                    </div>
                </div>
                <div className="login_center_content_div">
                    <div className="user_login_line_div">
                        <span className="user_login_tip_info">手机号:</span>
                        <input className="user_login_user_input" value={phone} onChange={(e) => {
                            if (e && e.target && e.target.value) {
                                setPhone(e.target.value)
                            } else {
                                setInvite("")
                            }
                        }} />
                    </div>
                    <div className="user_login_line_div">
                        <span className="user_login_tip_info">密码:</span>
                        <input className="user_login_user_input" value={password} onChange={(e) => {
                            if (e && e.target && e.target.value) {
                                setPassword(e.target.value)
                            } else {
                                setInvite("")
                            }
                        }} type="password" />
                    </div>
                    <div>
                    </div>
                    <div className="user_login_line_div">
                        <button className="user_login_button" onClick={() => {
                            if (phone && phone.length > 0 && password && password.length > 0) {
                                sendLoginRequest(phone, password)
                            } else {
                                alert("用户名密码，不能为空")
                            }
                        }}>登录</button>
                    </div>
                </div>
            </div>
        </ZJPageFrame>
    )
}

export default LCUserLoginPage